const sectionMenuButtons = document.querySelectorAll('[data-js-open-section-menu]')

sectionMenuButtons.forEach(function (sectionMenuButton) {
  sectionMenuButton.addEventListener('click', function () {
    const expanded = this.getAttribute('aria-expanded')

    if (expanded === 'false') {
      this.setAttribute('aria-expanded', true)
    } else {
      this.setAttribute('aria-expanded', false)
    }
  }, false)

  document.addEventListener('click', function (event) {
    if (event.target !== sectionMenuButton) {
      sectionMenuButton.setAttribute('aria-expanded', 'false')
    }
  })
})
