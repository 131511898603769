// In this file you can import assets like images or stylesheets
import { initAmountInputs } from './scripts/amount-input'

import './images/import.js'
import './videos/import.js'

import './styles/app.scss'

import './scripts/feature-detection.js'
import './scripts/js-enabled.js'
import './scripts/toggle-fieldset.js'
import './scripts/element-intersection-observer.js'
import './scripts/video-autoplay.js'
import './scripts/popover.js'
import './scripts/hamburger.js'
import './scripts/section-menu.js'
import './scripts/card-select.js'
import './scripts/accordion.js'
import './scripts/masonry.js'
import './scripts/page-scrolled.js'
import './scripts/quote-slider.js'
import './scripts/gallery.js'
import './scripts/toggle-button-groups.js'
import './scripts/menu.js'
import './scripts/address-book-select.js'
import './scripts/modal.js'
import { initCookieLayer } from './scripts/cookie-layer.js'

initAmountInputs()
initCookieLayer()
