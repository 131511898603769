import { alertNoCalendarEntrySelected, isPrototype } from './global-variables'
import { getTicketAmount } from './global-functions'
import { toggleFieldsets } from './toggle-fieldset'
import { initPopover } from './popover'
import { resetAmountInputs } from './amount-input'

export const setFieldsDependingOnFantissimaTicketAmount = function () {
  const fantissimaSelectMenuAlert = document.querySelector('[data-js-menu-alert]')
  const fantissimaSelectMenuSection = document.querySelector('[data-js-select-menu]')
  const fantissimaSelectAdditionalOptions = document.querySelector('[data-js-select-additional-options]')
  const selectTicketAlert = document.querySelector('[data-js-ticket-alert]')
  const menuFormRow = document.querySelector('[data-js-menu-form]')

  if (getTicketAmount() > 0) {
    if (alertNoCalendarEntrySelected.hasAttribute('hidden')) {
      fantissimaSelectMenuSection.removeAttribute('hidden')
      selectTicketAlert.setAttribute('hidden', 'hidden')
    }

    const maxValueForAdditionalOptions = getTicketAmount()
    const inputFieldsAdditionalOptions = Array.from(fantissimaSelectAdditionalOptions.querySelectorAll('[data-js-amount-input] input'))

    inputFieldsAdditionalOptions.forEach((inputFieldAdditionalOptions) => {
      inputFieldAdditionalOptions.max = maxValueForAdditionalOptions

      if (inputFieldAdditionalOptions.value > maxValueForAdditionalOptions) {
        inputFieldAdditionalOptions.value = maxValueForAdditionalOptions
      }
    })

    checkMenuFormValidity()
  } else {
    fantissimaSelectMenuSection.setAttribute('hidden', 'hidden')
    fantissimaSelectAdditionalOptions.setAttribute('hidden', 'hidden')
    selectTicketAlert.removeAttribute('hidden')
    fantissimaSelectMenuAlert.removeAttribute('hidden')
  }

  const menuSelection = document.querySelector('[data-js-menu-per-person]')
  const menuSelectionContent = menuSelection.innerHTML
  const menuFieldset = menuFormRow.querySelectorAll('[data-js-menu-fieldset]')
  const fieldsetLength = Array.from(menuFieldset).length

  function addMenuSelection () {
    const menuSelectionCount = fieldsetLength + 1
    let content = menuSelectionContent.replaceAll('§§id§§', String(menuSelectionCount))

    // replace index in form name attribute
    content = content.replaceAll(/(name=".*?\[.*?])(\[\d+?])/g,
      '$1[' + fieldsetLength + ']')

    menuFormRow.insertAdjacentHTML('beforeend', content)
  }

  function removeMenuSelection () {
    menuFieldset[fieldsetLength - 1].remove()
  }

  function checkMenuFormValidity () {
    const menuSelects = menuFormRow.querySelectorAll('select')
    let isValid = true
    for (const i in menuSelects) {
      if (menuSelects[i].value === '') {
        isValid = false
        break
      }
    }

    const menuRequiredTextarea = menuFormRow.querySelectorAll('textarea[required]')
    for (const i in menuRequiredTextarea) {
      if (menuRequiredTextarea[i].value === '') {
        isValid = false
        break
      }
    }

    if (!fantissimaSelectMenuSection.hasAttribute('hidden') && isValid) {
      fantissimaSelectMenuAlert.setAttribute('hidden', 'hidden')
      fantissimaSelectAdditionalOptions.removeAttribute('hidden')

      const popoverButtons = fantissimaSelectAdditionalOptions.querySelectorAll('[data-js-open-popover]')
      if (popoverButtons) {
        initPopover(popoverButtons)
      }
    } else {
      fantissimaSelectMenuAlert.removeAttribute('hidden')
      fantissimaSelectAdditionalOptions.setAttribute('hidden', 'hidden')
    }

    if (isPrototype && isValid) {
      const addToCartButton = document.querySelector('[data-js-add-to-cart]')

      addToCartButton.addEventListener('click', function (event) {
        event.preventDefault()

        if (isPrototype) {
          document.querySelector('[data-js-add-to-cart-success]').showModal()
          resetAmountInputs()

          document.querySelector('[data-js-modal-close]').addEventListener('click', function () {
            this.closest('[data-js-add-to-cart-success]').close()
          })
        }
      })
    }
  }

  if (getTicketAmount() > fieldsetLength) {
    addMenuSelection()
  } else if (fieldsetLength && getTicketAmount() < fieldsetLength) {
    removeMenuSelection()
  }

  toggleFieldsets()

  fantissimaSelectMenuSection.querySelectorAll('select, input, textarea').forEach((formField) => {
    formField.addEventListener('change', function () {
      checkMenuFormValidity()
    })
  })

  fantissimaSelectMenuSection.querySelectorAll('textarea').forEach((formField) => {
    formField.addEventListener('input', function () {
      checkMenuFormValidity()
    })
  })
}
